.background-legal {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: #f0f0f0;
    z-index: 9999;
    overflow-y: scroll;
}

.background-legal .container-legal {
    max-width: 1440px;
    width: 100%;
    height: 100%;
    padding: 20px;
    margin: 0 auto;
}

.background-legal .container-legal .container-back {
    width: 100%;
    display: flex;
    justify-content: start;
    align-items: center;
    margin-bottom: 20px;
}

.background-legal .container-legal .container-back .back-icon {
    font-size: 25px;
    color: #333;
    transition: color .3s;
    cursor: pointer;
}

.background-legal .container-legal .container-back .back-icon:hover {
    color: #4e4e4e;
}

.background-legal .container-legal h1 {
    font-size: 25px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-align: center;
    margin: 30px 0px 60px 0px;
    color: #333;
}

.background-legal .container-legal h2 {
    font-size: 20px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    color: #333;
    margin: 30px 0 10px 0;
}

.background-legal .container-legal p {
    font-size: 16px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    color: #333;
}

.background-legal .container-legal p strong {
    font-size: 16px;
    font-weight: 500;
    font-family: 'Poppins', sans-serif;
    color: #333;
}

.background-legal .container-legal h3 {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    text-align: end;
    display: flex;
    align-items: center;
    justify-content: end;
    color: #333;
    height: 100px;
}

.background-legal .container-legal h3 a {
    font-size: 18px;
    font-family: 'Poppins', sans-serif;
    font-weight: 600;
    text-decoration: none;
    margin-left: 4px;
    color: #333;
}
