@keyframes slideIn {
    from {
        transform: translate(-50%, -100%);
    }
    to {
        transform: translate(-50%, 0);
    }
}

.alert-message {
    position: fixed;
    top: 2%;
    left: 50%;
    transform: translateX(-50%);
    width: 300px;
    height: auto;
    color: white;
    display: flex;
    flex-direction: column;
    padding: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    z-index: 1000;
    animation: slideIn 0.5s forwards;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.alert-message h4 {
    font-size: 16px;
    margin-bottom: 5px;
    font-weight: 600;
}

.alert-message p {
    font-size: 14px;
    font-weight: 400;
}

.alert-message.error {
    background-color: rgb(211, 87, 87);
}

.alert-message.success {
    background-color: rgb(87, 211, 93);
}
