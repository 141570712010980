.background-home {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: auto;
    margin: 0 auto;
    position: relative; 
}

@media (max-width: 600px) {

    .background-home .container-home-2 .title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }

    .background-home .container-home-5 {
        width: 100%;
        height: auto;
        padding: 0 20px;
        max-width: 1440px;
        margin-bottom: 180px;
        font-family: "Poppins", sans-serif;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 h2 {
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 50px;
    }
    
    .background-home .container-home-5 .container-box {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .box-1 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .box-1 img {
        width: 100%;
        height: auto; 
        object-fit: cover;
        border: 5px solid #ffffff;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 {
        width: 100%;
        flex-grow: 1;
        display: flex;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2 img,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 5px solid #ffffff;
        flex-grow: 1; 
    }
}

@media (min-width: 600px) and (max-width: 1200px) {

    .background-home .container-home-5 {
        width: 90%;
        height: auto;
        padding: 0 60px;
        max-width: 1440px;
        margin-bottom: 180px;
        font-family: "Poppins", sans-serif;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 h2 {
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 50px;
    }
    
    .background-home .container-home-5 .container-box {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .box-1 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .box-1 img {
        width: 100%;
        height: auto; 
        object-fit: cover;
        border: 5px solid #ffffff;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 {
        width: 100%;
        flex-grow: 1;
        display: flex;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2 img,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 5px solid #ffffff;
        flex-grow: 1; 
    }
}

@media (min-width: 350px) and (max-width: 600px) {
    
    .background-home .container-home-1 {
        position: relative;
        width: 100%;
        height: 400px; 
        background-color: rgb(224, 224, 224);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }
    
    .background-home .container-home-1 .background-home-1 {
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        background-color: #3030305b;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 5px solid #f5f5f5;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call {
        position: absolute;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.651);
        border-radius: 10px;
        margin: 0 20px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call h1 {
        text-align: center;
        font-size: 22px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 5px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call p {
        text-align: center;
        font-style: italic;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin-bottom: 35px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button {
        padding: 10px 15px;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        border: none;   
        background-color: #333;
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button:hover {
        background-color: #494949;
    }
    
    .background-home .container-home-2 {
        width: 100%;
        margin: 90px 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-2 h1 {
        width: 80%;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-2 .container-box {
        width: 260px;
        display: flex;
        justify-content: center;
    }
    
    .background-home .container-home-2 .wrapper {
        width: 100%;
        aspect-ratio: 1 / 1;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
    }
    
    .background-home .container-home-2 .container-box .box h1 {
        font-size: 25px;
        font-weight: 500;
    }
    
    .background-home .container-home-2 .container-box .box p {
        font-weight: 400;
        font-size: 16px;
        text-align: center; 
    }
    
    .background-home .container-home-3 {
        width: 100%;
        padding: 50px 0;
        margin-bottom: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f5f5f5;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-3 h1 {
        width: 80%;
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-3 .container-box {
        width: 100%;
        max-width: 1440px;
        display: flex;
        justify-content: center;
        padding: 50px 60px;
    }
    
    .background-home .container-home-3 .box .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-3 .box img {
        width: 100%; 
        max-width: 200px; 
        height: auto;
        object-fit: cover;
    }
    
    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .slick-prev:before, .slick-next:before {
        color: white;
    }
    
    .background-home .container-home-4 {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 90px;
        display: flex;
        flex-direction: column; 
        align-items: center;
        gap: 40px; 
    }
    
    .background-home .container-home-4 .box {
        width: 100%;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
        padding: 20px;
    }
    
    .background-home .container-home-4 .box h3 {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    
    .background-home .container-home-4 .box p {
        font-size: 15px;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 20px;
    }    
    
}

@media (min-width: 600px) and (max-width: 1200px) {
    
    .background-home .container-home-1 {
        position: relative;
        width: 100%;
        height: 300px; 
        background-color: rgb(224, 224, 224);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }
    
    .background-home .container-home-1 .background-home-1 {
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        background-color: #3030305b;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 5px solid #f5f5f5;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call {
        position: absolute;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.651);
        border-radius: 10px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call h1 {
        text-align: center;
        font-size: 22px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 5px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call p {
        text-align: center;
        font-style: italic;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin-bottom: 35px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button {
        padding: 10px 15px;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        border: none;   
        background-color: #333;
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button:hover {
        background-color: #494949;
    }
    
    .background-home .container-home-2 {
        width: 100%;
        padding: 0 20px;
        max-width: 1000px;
        margin: 90px auto;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-2 .title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-2 .container-box {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .background-home .container-home-2 .box {
        width: 260px;
        aspect-ratio: 1 / 1;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        padding: 20px;
        margin: 10px;
    }
    
    .background-home .container-home-2 .box h1 {
        font-size: 25px;
        text-align: center;
        font-weight: 500;
    }
    
    .background-home .container-home-2 .box p {
        font-weight: 400;
        font-size: 16px;
        text-align: center; 
    }
    
    .background-home .container-home-2 .container-box .box:nth-child(3) {
        width: 260px;
    }
    
    .background-home .container-home-3 {
        width: 100%;
        padding: 50px 0;
        margin-bottom: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f5f5f5;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-3 h1 {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-3 .container-box {
        width: 100%;
        max-width: 1440px;
        display: flex;
        justify-content: center;
        padding: 50px 60px;
    }
    
    .background-home .container-home-3 .box .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-3 .box img {
        width: 100%; 
        max-width: 200px; 
        height: auto;
        object-fit: cover;
    }
    
    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .slick-prev:before, .slick-next:before {
        color: white;
    }
    
    .background-home .container-home-4 {
        width: 100%;
        padding: 0 20px;
        margin-bottom: 90px;
        display: flex;
        flex-direction: column; 
        align-items: center;
        gap: 40px; 
    }
    
    .background-home .container-home-4 .box {
        width: 80%;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
        padding: 20px;
    }
    
    .background-home .container-home-4 .box h3 {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    
    .background-home .container-home-4 .box p {
        font-size: 15px;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 20px;
    }    
}

@media (min-width: 1200px) and (max-width: 1440px) {
    
    .background-home .container-home-1 {
        position: relative;
        width: 100%;
        height: 60vh; 
        background-color: rgb(224, 224, 224);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }
    
    .background-home .container-home-1 .background-home-1 {
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        background-color: #3030305b;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 5px solid #f5f5f5;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call {
        position: absolute;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.651);
        border-radius: 10px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call h1 {
        text-align: center;
        font-size: 22px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 5px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call p {
        text-align: center;
        font-style: italic;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin-bottom: 35px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button {
        padding: 10px 15px;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        border: none;   
        background-color: #333;
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button:hover {
        background-color: #494949;
    }
    
    .background-home .container-home-2 {
        width: 100%;
        max-width: 1000px;
        margin: 90px 0;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-2 .title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-2 .container-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .background-home .container-home-2 .box {
        width: 30%;
        aspect-ratio: 1 / 1;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
    }
    
    .background-home .container-home-2 .box h1 {
        font-size: 25px;
        text-align: center;
        font-weight: 500;
    }
    
    .background-home .container-home-2 .box p {
        font-weight: 400;
        font-size: 16px;
        text-align: center; 
    }
    
    .background-home .container-home-3 {
        width: 100%;
        padding: 50px 0;
        margin-bottom: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f5f5f5;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-3 h1 {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-3 .container-box {
        width: 100%;
        max-width: 1440px;
        display: flex;
        justify-content: center;
        padding: 50px 60px;
    }
    
    .background-home .container-home-3 .box .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-3 .box img {
        width: 100%; 
        max-width: 200px;
        height: auto;
        object-fit: cover;
    }
    
    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .slick-prev:before, .slick-next:before {
        color: white;
    }
    
    .background-home .container-home-4 {
        width: 90%;
        margin-bottom: 90px;
        display: flex;
        justify-content: space-between;
    }
    
    .background-home .container-home-4 .box {
        width: 45%;
        height: auto;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
        padding: 20px;
    }
    
    .background-home .container-home-4 .box h3 {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    
    .background-home .container-home-4 .box p {
        font-size: 15px;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 20px;
    }
    
    .background-home .container-home-5 {
        width: 100%;
        height: auto;
        padding: 0 60px;
        max-width: 1440px;
        margin-bottom: 180px;
        font-family: "Poppins", sans-serif;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 h2 {
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 50px;
    }
    
    .background-home .container-home-5 .container-box {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .background-home .container-home-5 .container-box .box-1 {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .box-1 img {
        width: 100%;
        height: auto; 
        object-fit: cover;
        border: 5px solid #ffffff;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 {
        width: 100%;
        flex-grow: 1;
        display: flex;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2 img,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 5px solid #ffffff;
        flex-grow: 1; 
    }
}

@media (min-width: 1440px) {

    .background-home .container-home-1 {
        position: relative;
        width: 100%;
        height: 60vh; 
        background-color: rgb(224, 224, 224);
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-bottom: 2px solid #ececec23;
    }
    
    .background-home .container-home-1 .background-home-1 {
        position: absolute;
        width: 100%;
        height: 100%;
        backdrop-filter: blur(8px);
        background-color: #3030305b;
        color: #000000;
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 5px solid #f5f5f5;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call {
        position: absolute;
        padding: 15px;
        background-color: rgba(255, 255, 255, 0.651);
        border-radius: 10px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call h1 {
        text-align: center;
        font-size: 22px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        margin-bottom: 5px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call p {
        text-align: center;
        font-style: italic;
        font-size: 18px;
        font-family: "Poppins", sans-serif;
        font-weight: 500;
        margin-bottom: 35px;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn {
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button {
        padding: 10px 15px;
        font-size: 16px;
        font-family: "Poppins", sans-serif;
        font-weight: 600;
        border: none;   
        background-color: #333;
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
    }
    
    .background-home .container-home-1 .background-home-1 .container-call .container-btn button:hover {
        background-color: #494949;
    }
    
    .background-home .container-home-2 {
        width: 100%;
        max-width: 1000px;
        margin: 90px 0;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-2 .title {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-2 .container-box {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    
    .background-home .container-home-2 .box {
        width: 30%;
        aspect-ratio: 1 / 1;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
    }
    
    .background-home .container-home-2 .box h1 {
        font-size: 25px;
        text-align: center;
        font-weight: 500;
    }
    
    .background-home .container-home-2 .box p {
        font-weight: 400;
        font-size: 16px;
        text-align: center; 
    }
    
    .background-home .container-home-3 {
        width: 100%;
        padding: 50px 0;
        margin-bottom: 90px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #f5f5f5;
        font-family: "Poppins", sans-serif;
    }
    
    .background-home .container-home-3 h1 {
        text-align: center;
        font-weight: 500;
        font-size: 30px;
        margin-bottom: 30px;
    }
    
    .background-home .container-home-3 .container-box {
        width: 100%;
        max-width: 1440px;
        display: flex;
        justify-content: center;
        padding: 50px 60px;
    }
    
    .background-home .container-home-3 .box .img-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    
    .background-home .container-home-3 .box img {
        width: 100%; 
        max-width: 200px;
        height: auto;
        object-fit: cover;
    }
    
    .slick-slide {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 0 10px; 
    }
    
    .slick-prev:before, .slick-next:before {
        color: white;
    }
    
    .background-home .container-home-4 {
        width: 100%;
        max-width: 1000px;
        margin-bottom: 90px;
        display: flex;
        justify-content: space-between;
    }
    
    .background-home .container-home-4 .box {
        width: 45%;
        height: auto;
        border: 1px solid #c4c4c4;
        border-radius: 10px;
        font-family: "Poppins", sans-serif;
        padding: 20px;
    }
    
    .background-home .container-home-4 .box h3 {
        font-size: 20px;
        margin-bottom: 20px;
        font-weight: 500;
    }
    
    .background-home .container-home-4 .box p {
        font-size: 15px;
        font-weight: 400;
        font-style: italic;
        margin-bottom: 20px;
    }
    
    .background-home .container-home-5 {
        width: 100%;
        height: auto;
        padding: 0 60px;
        max-width: 1440px;
        margin-bottom: 180px;
        font-family: "Poppins", sans-serif;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 h2 {
        font-size: 30px;
        text-align: center;
        font-weight: 500;
        margin-bottom: 50px;
    }
    
    .background-home .container-home-5 .container-box {
        width: 100%;
        display: flex;
        flex-direction: row;
    }
    
    .background-home .container-home-5 .container-box .box-1 {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .box-1 img {
        width: 100%;
        height: auto; 
        object-fit: cover;
        border: 5px solid #ffffff;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 {
        width: 50%;
        display: flex;
        flex-direction: column;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 {
        width: 100%;
        flex-grow: 1;
        display: flex;
    }
    
    .background-home .container-home-5 .container-box .container-box-2 .box-2 img,
    .background-home .container-home-5 .container-box .container-box-2 .box-3 img {
        width: 100%;
        height: auto;
        object-fit: cover;
        border: 5px solid #ffffff;
        flex-grow: 1; 
    }
    
}
