@media (max-width: 700px) {
    

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        flex-direction: column;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 900px;
        width: 100%;
        height: 300px;
    }

    footer .container-top h1 {
        text-align: center;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;
    }

    footer .container-top .box:nth-child(1) span {
        text-align: center;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) {
        height: auto;
        font-style: normal;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        width: 90%;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 15px;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}

@media (min-width: 700px) and (max-width: 1200px) {
    

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 700px;
        width: 100%;
        height: 150px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(1) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        width: 90%;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 15px;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}

@media (min-width: 1200px) and (max-width: 1440px) {

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 900px;
        width: 100%;
        height: 150px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(1) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        position: relative;
        width: 90%;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        position: absolute;
        right: 0;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }

    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}

@media (min-width: 1440px) {

    footer {
        position: relative;
        width: 100%;
        height: auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: default;
        background-color: #0e0e0e;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    }

    footer .container-top {
        font-family: 'Lato', sans-serif;
        font-size: 16px;
        font-weight: 700;
        font-style: italic;
        text-align: center;
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        color: #ffffff;
        padding: 20px 60px;
        margin: 50px 0;
        max-width: 900px;
        width: 100%;
        height: 150px;
    }

    footer .container-top h1 {
        text-align: start;
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 500;
        margin-bottom: 25px;
    }

    footer .container-top .box:nth-child(1) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(1) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(1) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-top .box:nth-child(2) {
        height: auto;
        font-style: normal;
        display: flex;
        flex-direction: column;
    }

    footer .container-top .box:nth-child(2) div {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 10px;
    }

    footer .container-top .box:nth-child(2) span {
        text-align: start;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 300;
    }

    footer .container-top .box:nth-child(2) div .icon-contact {
        width: 30px;
        font-size: 18px;
        margin-right: 5px;
    }

    footer .container-center {
        position: relative;
        border-top: 1px solid #b8b8b893;
        padding: 20px;
        margin-top: 30px;
        max-width: 1320px;
        width: 100%;
    }

    footer .container-center h5 {
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: center;
        color: #ffffff;
    }

    footer .container-center h6 {
        position: absolute;
        right: 0;
        font-size: 14px;
        font-family: 'Poppins', sans-serif;
        font-weight: 400;
        font-style: italic;
        text-align: center;
        color: #ffffff;
        margin-bottom: 10px;
    }


    footer .container-bottom {
        font-size: 16px;
        font-family: 'Poppins', sans-serif;
        font-weight: 600;
        font-style: italic;
        text-align: right;
        color: #ffffff;
        background-color: #000000;
        padding: 12.5px 50px;
        width: 100%;
    }
}